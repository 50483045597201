<template>
  <div class="feedback">
    <div class="feedback__container">
      <div class="feedback__column">
        <h3 class="feedback__title">Ми на зв’язку і завжди готові допомогти</h3>
        <div class="feedback__time">
          <p>У будні з 9:00 до 18:00</p>
          <p>У вихідні з 9:00 до 13:00</p>
        </div>
        <div class="feedback__tel">
          <p>Гаряча лінія</p>
          <p>+ 38 066 434 30 28</p>
        </div>
        <div class="feedback__email">
          <p>Електронна пошта</p>
          <p>spd@proconnect.construction</p>
        </div>
<!--        <div class="socials">-->
<!--          <a class="socials_link" href=""><img src="@/assets/img/youtube.svg"/></a>-->
<!--          <a class="socials_link" href=""><img style=" width: 23px; height: 23px;"-->
<!--                                               src="@/assets/img/instagram.svg"/></a>-->
<!--          <a class="socials_link" href=""><img style=" width: 20px; height: 25px;" src="@/assets/img/tiktok2.png"/></a>-->
<!--        </div>-->
      </div>

      <div class="feedback__column">

        <form @submit.prevent.stop="submit" class="feedback__column__form form" action="">
          <div class="form__input">
            <PlaceholderTextInput
                :modelValue="formData.name = user['name']"
                class="form__input__text"
                style=" grid-area: 1 / 1 / 2 / 3; "
                type="text"
                placeholder="Ім'я*"
                @update:modelValue="formData.name = $event"
            />
            <PlaceholderTextInput
                :modelValue="formData.email = user['email']"
                class="form__input__email"
                style=" grid-area: 2 / 1 / 3 / 2; "
                type="email"
                placeholder="Email*"
                @update:modelValue="formData.email = $event"
            />
            <PlaceholderTextInput
                :modelValue="formData.phone || ''"
                class="form__input__tel"
                :required="false"
                style=" grid-area: 2 / 2 / 3 / 3; "
                type="tel"
                placeholder="Номер телефону"
                @update:modelValue="formData.phone = $event"
            />

          </div>
          <Dropdown
              class="topic"
              :list="listTopic"
              @select="onTopicSelect"
              :hasSubGroups="false"
              :multipleChoice="false"
              placeholder="Оберіть тему звернення*"
              :modelValue="getTopic"
          />
          <PlaceholderTextInput
              :modelValue="formData.message || ''"
              class="form__input__textarea"
              typeTag="textarea"
              style="height: 112px;"
              type="textarea"
              placeholder="Повідомлення*"
              @update:modelValue="formData.message = $event"

          />
          <div class="form__select">
            <div class="form__select checkbox">
              <CheckboxInput
                  v-model="formData.agreement"
                  class="checkbox__input"
                  required
                  type="checkbox"/>
              <label
                  class="checkbox__label"
                  for="agree">
                <span class="checkbox__text">Я ознайомився з
                <a href="/privacy">Політикою конфіденційності</a>
              Pro Connect i даю згоду на використання моїх даних відповідно до неї</span></label>
            </div>

            <Button
                @click="sentFeedback"
                class="form__select__button "
            >Надіслати
            </Button>
            <div
                v-if="this.successfulSending === true"
                class='message'
            >
              <p>Ваше повідомлення успішно відправлено!</p>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>

</template>
<script>


import Button from "@/components/UI/Button.vue";
import Dropdown from "@/components/Dropdown.vue";

export default {
  name: "FeedBack",
  components: {Dropdown, Button},
  data: () => ({
    formData: {
      name: null,
      email: null,
      phone: null,
      message: null,
      topic_id: null,
      agreement: false,
    },
    successfulSending: false,
    isPopupVisible: "false",
  }),
  methods: {
    async sentFeedback() {
      if (this.hasError()) {
        return;
      }
      try {
        this.formData.topic_id = this.formData.topic_id[0];
        await this.fetch('/api/v1/users/support-email/', this.formData, 'POST');
        this.clearFormData();
        this.successfulSending = true;
      } catch (error) {
        console.error(error);
      }
    },
    clearFormData() {
      this.formData.phone = '';
      this.formData.message = '';
      this.formData.topic_id = '';
      this.formData.agreement = false;
    },
    hasError(field = undefined) {
      if (field === undefined) {
        for (let key in this.formData) {
          if (!this.formData[key]) {
            return true;
          }
        }
        return false;
      } else {
        return !this.formData[field];
      }
    },
    onTopicSelect(e) {
      this.formData.topic_id = [e];
    },

  },
  computed: {
    getTopic() {
      if (!this.listTopic || !this.formData.topic_id) return "";
      let result = this.listTopic.find((type) => type.id === this.formData.topic_id[0]);
      return result ? result.title : "";
    },
  }
}


</script>


<style scoped lang="scss">
.feedback {
  max-width: 1100px;
  margin: 0 auto;
  padding: 15px;
  @media (max-width: 768px){
    padding: 25px;
  }

  .feedback__container {
    margin-top: 140px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    @media (max-width: 768px){
      margin-top: 10px;
      gap: 25px;
      flex-direction: column;
    }

    .feedback__column {
      flex: 0 1 50%;


      .feedback__title {
        font-size: 32px;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 16px;
        @media (max-width: 768px){
          font-size: 26px;
        }
        @media (max-width: 375px){
          font-size: 20px;
        }

      }

      .feedback__time {
        margin-bottom: 40px;
        font-weight: 500;
        position: relative;
        @media (max-width: 768px){
          margin-bottom: 15px;
        }

        & p {
          display: inline;

        }

        & p:first-child {
          margin-right: 32px;

        }

        & p:first-child:after {
          content: "";
          position: absolute;
          left: 191px;
          height: 20px;
          width: 1.5px;
          background-position: center center;
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAgCAYAAAArBentAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHBSURBVHgBjVSxcsIwDLWSFkh77cbAHRPHBCMf0HTp2KXlf8LQfgx8RT+AgePCUuianYWFWH0ytrEDd63uRBzlWU96MlYKxswkLuvdd1WIu7iylsgPkX8PDWHiCCibG++qKArZ7TOkjmI2m1G326Wn/OUZAXp7f/0K03vqsixpsVikWms6wm0Sz3DjFgCdqABiNsAkKMnvoMlkYj7WJ5Dq9XrJaDTydSaqaZpJw6uqisKOmpfLpcmsbQAZ1WaziZuxUpiPiQ2macqgjtlEHtR4i+Vduf75WK92n7KGt5yEJqNoCGoKu1RnsTmkNgFQQ0OOAFHXFmg6lYBrSGrEN45qxMPUKPWt4MguNbZdsqRRk98t2ZGRIx1l1kKz3+9VuDvLshgoBnHDjo0dDof44AZn7+oJjrqG4CKP7/gqUJ1mfXFKZNZWuvMxU39YdMxEkpB6OBxSMyM7D3dut1t2QGWbke8tO41HcVlD21aT1QDhGfxBvN/vZxdAmXWe5yJ+B35vXda3zYzm72kzdGzmtmx214pHT6dT0U0DrCH+EaEaFwK76yax1DyfzzUeNcD1YDAQlfR4PI7nH9xa4cwpvM3+bb9OtMcXEF1yfAAAAABJRU5ErkJggg==");

        }

      }

      .feedback__tel {
        margin-bottom: 40px;
        @media (max-width: 768px){
          margin-bottom: 15px;
        }
        p:nth-child(1) {
          margin-bottom: 4px;
          font-weight: 500;
        }

        p:nth-child(2) {
          font-size: 24px;
          font-weight: 700;
          @media (max-width: 768px){
            font-size: 20px;
          }
          @media (max-width: 375px){
            font-size: 16px;
          }
        }
      }


      .feedback__email {

        p:nth-child(1) {
          margin-bottom: 4px;
          font-weight: 500;
        }

        p:nth-child(2) {
          font-weight: 700;
          font-size: 24px;
          @media (max-width: 768px){
            font-size: 20px;
          }
          @media (max-width: 375px){
            font-size: 16px;
          }

        }
      }

      .form {
        .form__input {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 16px;
          grid-row-gap: 16px;
          margin-bottom: 16px;

          input {
            padding: 10px 15px;
            border-radius: 8px;
          }
        }

        .form__input__textarea {
          margin-top: 16px;
        }

        .checkbox {
          display: flex;
          align-items: center;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
          width: 100%;
          margin-bottom: 32px;

          &:active {
            border-color: red;
          }

          .checkbox__label {
            padding-left: 10px;
          }
        }

      }


    }

  }

  .message {
    text-align: center;
    color: #4EB2FF;
    margin-top: 15px;
  }


  .socials {
    display: flex;
    gap: 15px;
    margin-top: 40px;
    @media (max-width: 768px){
      margin-top: 15px;
    }

    .socials_link {
      display: flex;
      padding: 10px;
      max-width: 36px;
      min-width: 36px;
      height: 36px;
      color: var(--color);
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
      border-radius: 50%;
      border: 1px solid transparent;
      align-items: center;
      justify-content: center;
      background: #9ea9ac;
      transition: .2s;
    }
  }


}
</style>