export default {
    state: {
        filterProjects: [],
        filterUsers: [],
    },
    mutations: {
        'filterProjects:change': (state, newFilter) => {
            return state.filterProjects = [...newFilter];
        },
        'filterUsers:change': (state, newFilter) => {
            return state.filterUsers = [...newFilter];
        }
    },
    actions: {
        changeFilterProjects({ commit }, data) {
            return commit('filterProjects:change', data);
        },
        changeFilterUsers({ commit }, data) {
            return commit('filterUsers:change', data);
        },
        
    },
    getters: {
        
        getFilterProjects(state) {
            return state.filterProjects;
        },
        getFilterUsers(state) {
            return state.filterUsers;
        }
    },
}