import axios from "axios";
import store from "@/store";

let baseURL = "https://api.proconnect.construction/api/v1/",
    $api = axios.create({ baseURL });

$api.interceptors.request.use((config) => {
    if (store.state.user.user.authorized) {
        config.headers.Authorization = `Bearer ${localStorage.getItem("access_token")}`;
    }
    return config;
});

$api.interceptors.response.use(
    (config) => config,
    async (error) => {
        let originalRequest = error.config;
        if (error.response.status === 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                let response = await axios.post(`${baseURL}users/refresh-login/`, {
                    refresh: localStorage.getItem("refresh_token"),
                });
                localStorage.setItem("access_token", response.data.access);
                return $api.request(originalRequest);
            } catch (e) {
                console.log("Not authorized");
                return;
            }
        }
        return error.response;
    }
);

export default $api;
