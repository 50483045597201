<template>
  <div class="deletion-popup__wrapper" ref="popup_wrapper" @click="handleClose">
    <div class="deletion-popup">
      <div class="deletion-popup__close">
        <slot name="close"></slot>
      </div>
      <div class="deletion-popup__block block">
        <div class="block__image">
          <slot name="image"></slot>
        </div>
        <div class="block__header">
          <slot name="header"></slot>
        </div>
        <div class="block__content">
          <slot name="text"></slot>

        </div>
        <div class="block__footer button">
          <slot name="buttonCancel"></slot>
          <slot name="buttonDelete"></slot>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


export default {
  name: "ModalBasic",
  methods: {
    handleClose(event) {
      if (event.target === this.$refs['popup_wrapper']) {
        this.$emit('closePopup')
      }
    }
  },
}
</script>


<style scoped lang="scss">

.deletion-popup__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
  max-height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .deletion-popup {
    position: fixed;
    padding: 18px;
    top: 250px;
    width: 492px;
    border: 1px solid #DDDCE8;
    left: calc(50% - 200px);
    z-index: 33;
    background-color: white;
    border-radius: 8px;

    &__close {
      float: right;

    }
    .block{
      padding: 15px;
      &__image {
        text-align: center;
      }
      &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        text-align: center;

      }

      &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
      }

     &__footer {
        display: flex;
        justify-content: center;
        gap: 20px;
      }
    }


  }

}

</style>