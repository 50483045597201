<template>
  <div class="dropdown">
    <div
        class="menu"
        :class="{ active: areOptionsVisible,position:!hasButtons }"
        @click="toggleDropdownVisible"
    >
      <div v-if="useSearch">
        <PlaceholderTextInput
            :desktopVersion="desktopVersion"
            style="--input-h: 36px"
            class="inputSearch"
            :error="error"
            big-paddings
            :modelValue="searchTextActive || multipleChoice ? searchText : modelValue"
            :class="{ active: areOptionsVisible }"
            :img="[require('@/assets/img/search2.svg')]"
            :placeholder="placeholder"
            @update:modelValue="searchText = $event"
            @blur="searchTextActive = false"
            @focus="
            searchTextActive = true
            //searchText = modelValue;
          "
            @click.stop="
            () => {
              if (!areOptionsVisible) areOptionsVisible = true;
            }

          "
        />
        <div style="position: relative">
          <div
              class="arrowList"
              :class="{ active: areOptionsVisible }"
              v-show="this.list.length"
          ></div>
        </div>

      </div>
      <div v-else>
        <PlaceholderTextInput
            :desktopVersion="desktopVersion"
            style="--input-h: 36px"
            :error="error"
            class="inputSearch"
            :big-paddings="useSearch"
            :modelValue="searchTextActive || multipleChoice ? searchText : modelValue"
            :class="{ active: areOptionsVisible }"
            :placeholder="placeholder"
            @update:modelValue="searchText = $event"
            @blur="searchTextActive = false"
            @focus="
            searchTextActive = true
            //searchText = modelValue;
          "
            @click.stop="
            () => {
              if (!areOptionsVisible) areOptionsVisible = true;
            }
          "
        />

        <div style="position: relative">
          <div
              class="arrowList"
              :class="{ active: areOptionsVisible }"
              v-show="this.list.length"
          />
        </div>


      </div>
      <div v-if="hasSubGroups">
        <TransitionGroup
            enter-active-class="fade-show"
            leave-active-class="fade-hide"
        >
          <div
              key="1"
              class="menu_list"
              :class="{ small__block: largeBlock, mobileVersion: !desktopVersion }"
              v-show="areOptionsVisible && selectorItems.length"
              @click.stop="() => {}"
          >
            <ul class="list">
              <li
                  v-for="item in selectorItems"
                  :key="item.mainGroup"
              >
                <div
                    @click.stop="handleActive"
                    :data-group="item.mainGroup"
                    class="menu__link"
                    :class="{ 'arrow && activeLink': activeClasses.includes(item.mainGroup) }"
                >
                  {{ item.mainGroup }}
                </div>

                <ul
                    :class="{ active: activeClasses.includes(item.mainGroup) || searchText }"
                    class="submenu__list"
                >
                  <div v-if="multipleChoice === true && !searchText">
                    <button
                        class="button__all"
                        @click.stop.prevent="multipleSelectOption(item.items,types)"
                    >
                      <slot></slot>
                    </button>
                  </div>
                  <li
                      v-for="el in item.items"
                      :key="el.value"
                      :selected="el.title"
                      @click.stop="selectOption(el)"
                  >
                    <!-- @click.stop="selectOption(el)" -->
                    <label class="submenu__link">
                      <div
                          v-if="multipleChoice"
                          style="display: flex; gap: 5px; align-items: center"
                      >
                        <CheckboxInput
                            @onChange="handleChange(types,el)"
                            @click.stop="handleClickChange"
                            :modelValue="el.checked"
                        />
                        <span
                            @click.stop.prevent="handleClickChange(types,el)"
                        >{{ el.title }}</span
                        >
                      </div>
                      <div v-else>
                        <span>{{ el.title }}</span>
                      </div>
                    </label>
                    <!-- <div class="submenu__link">{{ el.title }}</div> -->
                  </li>
                </ul>
              </li>
            </ul>
            <div
                v-if="multipleChoice && hasButtons"
                class="group-button"
            >
              <Button
                  class="button"
                  @click.stop="doSelect"
              >Застосувати
              </Button>
              <ButtonAdd
                  class="button"
                  @click.stop="clearSelected"
              >Очистити
              </ButtonAdd>
            </div>
          </div>
          <div
              key="2"
              v-show="!selectorItems.length && areOptionsVisible"
              :class="{mobileVersion: !desktopVersion }"
              class="menu_list"
          ></div>
        </TransitionGroup>
      </div>
      <div v-else>
        <transition-group>
          <div
              v-show="areOptionsVisible"
              class="menu_list"
              :class="{ mobileVersion: !desktopVersion }"
              @click.stop="() => {}"
          >
            <ul class="list">
              <li
                  v-for="el in filteredList"
                  :key="el.value"
                  :selected="el.title"
                  @click.stop="selectOption(el)"
              >
                <label class="submenu__link without_group">
                  <div
                      style="display: flex; gap: 5px; align-items: center"
                      v-if="multipleChoice"
                  >
                    <CheckboxInput
                        @onChange="handleChange(types,el)"
                        @click.stop="handleClickChange"
                        :modelValue="el.checked"
                    />
                    <span
                        @click.stop.prevent="handleClickChange(types,el)"
                    >{{ el.title }}</span
                    >
                  </div>
                  <div v-else>
                    <span>{{ el.title }}</span>
                  </div>
                </label>
                <!-- <div class="submenu__link without_group">{{ el.title }}</div> -->
              </li>
            </ul>
            <div
                class="group-button"
                v-if="multipleChoice && hasButtons"
            >
              <Button
                  class="button"
                  @click.stop="doSelect"
              >Застосувати
              </Button>
              <ButtonAdd
                  class="button"
                  @click.stop="clearSelected"
              >Очистити
              </ButtonAdd>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonAdd from "./UI/ButtonAdd.vue";

export default {
  emits: ['onChange', 'select'],
  inheritAttrs: false,
  name: "DropdownV",
  data() {
    return {
      activeClasses: [],
      areOptionsVisible: false,
      selectorItems: [],
      title: "",
      searchText: "",
      searchTextActive: false,
    };
  },
  computed: {
    filteredList() {
      if (!this.searchText) return this.list;
      return this.list.filter((row) => row.title.toUpperCase().indexOf(this.searchText.toUpperCase()) !== -1);
    },
  },
  props: {
    multipleChoice: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    hasButtons: {
      type: Boolean,
      default: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
    hasSubGroups: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    titleName: {
      type: Boolean,
      default: true,
    },
    largeBlock: {
      type: Boolean,
      default: true,
    },
    useSearch: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    types: {
      type: String,
      default: "",
    },
    desktopVersion: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    filteredList() {
      this.groupRole();
    },
  },
  methods: {
    toggleDropdownVisible() {
      console.log('пока блоком')
      if (this.hasButtons) {
        this.areOptionsVisible = !this.areOptionsVisible;
        if (!this.areOptionsVisible) this.searchText = '';
      } else {
        this.areOptionsVisible = false;
      }
    },
    handleChange(types, el) {
      this.$emit('onChange', {types, el})
    },
    handleClickChange(types, el) {
      el.checked = !el.checked;
      this.$emit('onChange', {types, el})
    },
    doSelect() {
      if (this.hasSubGroups) {
        this.selectorItems.forEach((item) => {
          item.items.forEach((el) => {
            if (el.checked) {
              this.selectOption(el, false);
              this.title = el.title;
              el.checked = false;
            }
          });
        });
      } else {
        this.filteredList.forEach((el) => {
          if (el.checked) {
            this.selectOption(el, false);
            this.title = el.title;
            el.checked = false;
          }
        });
      }
      this.areOptionsVisible = false;
      this.activeClasses = [];
      this.searchText = "";
    },
    clearSelected() {
      if (this.hasSubGroups) {
        this.selectorItems.forEach((item) => {
          item.items.forEach((el) => {
            if (el.checked) {
              el.checked = false;
            }
          });
        });
      } else {
        this.filteredList.forEach((el) => {
          if (el.checked) {
            el.checked = false;
          }
        });
      }
    },
    handleActive(e) {
      const element = e.target.dataset.group;
      let elementIndex = this.activeClasses.indexOf(element);
      if (elementIndex > -1) {
        this.activeClasses.splice(elementIndex, 1);
      } else {
        this.activeClasses.push(element);
      }
    },
    selectOption(el, closeMenu = true) {
      if (this.hasSubGroups) {
        this.$emit("select", el.value);
        if (closeMenu) {
          this.title = el.title;
          this.areOptionsVisible = false;
          this.activeClasses = [];
          this.searchText = "";
        }
      } else {
        this.$emit("select", el.id);
        if (closeMenu) {
          this.title = el.title;
          this.areOptionsVisible = false;
          this.activeClasses = [];
          this.searchText = "";
        }
      }
    },
    multipleSelectOption(elementsArr, types) {
      elementsArr.forEach((el) => {
        if (el) {
          el.checked = true;
          this.$emit('onChange', {el, types});
        }
      });
    },
    groupRole() {
      const grouped = this.filteredList.map((element) => {
        const obj = {
          mainGroup: element.user_type_name,
          items: this.filteredList
              .filter((item) => item.user_type_name === element.user_type_name)
              .map((item) => {
                return {
                  value: item.id,
                  title: item.title,
                };
              }),
        };
        return obj;
      });
      const uniqueIds = [];
      this.selectorItems = grouped.filter((item) => {
        if (!uniqueIds.includes(item.mainGroup)) {
          uniqueIds.push(item.mainGroup);
          return true;
        }
        return false;
      });
    },
  },
  mounted() {
    this.groupRole();
  },
  components: {ButtonAdd},
};
</script>

<style lang="scss">
.dropdown {
  width: 100%;
  position: relative;

  .menu {
    position: relative;

    &.active {
      &:after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: auto;
      }
    }

    &.position {
      &:after {
        position: absolute;
      }
    }

    .inputSearch {
      --bg: --background-secondary;
      height: 36px;
      border-bottom: none;
      overflow-x: clip;

      &.active {
        //z-index: 3;

        & input {
          background-color: white;
          border-color: #c9c8d7;
          border-bottom: none;
          border-radius: 4px 4px 0 0;
        }

        &::before {
          content: " ";
          display: block;
          height: 1px;
          width: 90%;
          position: absolute;
          top: 98%;
          left: 5%;
          background: #e6e6e6;
        }

        &::after {
          content: " ";
          display: block;
          height: 1px;
          border-bottom: 1px solid white;
          z-index: 4;
          position: absolute;
          bottom: -1px;
          left: 1px;
          width: calc(100% - 1px);
        }
      }
    }

    .dropdown-title {
      border: 1px solid #dddce8;
      border-radius: 5px;
      padding: 8px 16px;
      cursor: pointer;
      line-height: 120%;
      color: #6f6f6f;
      height: 100%;
      position: relative;
      background-color: transparent;

      .arrow {
        content: "";
        font-size: 0;
        position: absolute;
        pointer-events: none;
        background-repeat: no-repeat;
        right: 8px;
        top: 30%;
        height: 20px;
        width: 28px;
        background-position: center center;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABzSURBVHgBjZDRDYAgDEQPGUBnYBYGcgNxAhfiQ/+cglm8S+CHVMIlTdPrvaSpizHuANac84kJ1TyWOicaxwSkzMXafCnlDSE4wezg/AygxDp5XfIyGb5HcA/J8235B1uQ5IyTtGxhWJAJdjAsaCjB7fWWPtufPZa0gxjnAAAAAElFTkSuQmCC");
      }
    }

    .arrowList {
      content: "";
      font-size: 0;
      position: absolute;
      pointer-events: none;
      background-repeat: no-repeat;
      right: 8px;
      bottom: 7px;
      height: 20px;
      width: 28px;
      background-position: center center;
      // background-color: var(--background-secondary);
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABzSURBVHgBjZDRDYAgDEQPGUBnYBYGcgNxAhfiQ/+cglm8S+CHVMIlTdPrvaSpizHuANac84kJ1TyWOicaxwSkzMXafCnlDSE4wezg/AygxDp5XfIyGb5HcA/J8235B1uQ5IyTtGxhWJAJdjAsaCjB7fWWPtufPZa0gxjnAAAAAElFTkSuQmCC");
      transition: background-color background-image 0.2s;
      z-index: 100;

      &.active {
        //   background-color: transparent;
        //   background-image: none;
        transition: background-color background-image 0.2s;
      }
    }

    .activeTitle {
      border-bottom: none;
      border-radius: 5px 5px 0px 0px;
      position: relative;
      border-color: #c9c8d7;
      background-color: white;
      transition: all 0.4s ease 0s;

      &::before {
        content: " ";
        display: block;
        height: 1px;
        width: 90%;
        position: absolute;
        top: 98%;
        left: 5%;
        background: #e6e6e6;
      }

      &::after {
        content: " ";
        display: block;
        position: relative;
        height: 1px;
        border-bottom: 2px solid white;
        z-index: 3;
        position: absolute;
        bottom: -2px;
        left: 1px;
        width: calc(100% - 1px);
      }
    }

    .menu_list {
      font-family: var(--font-primary);
      background-color: white;
      position: absolute;
      border-radius: 0px 0px 5px 5px;
      font-size: 16px;
      cursor: pointer;
      font-weight: 400;
      line-height: 120%;
      color: black;
      //border: 1px solid #c9c8d7;
      border-left: 1px solid #c9c8d7;
      border-bottom: 1px solid #c9c8d7;
      border-right: 1px solid #c9c8d7;
      //border-top: none;
      max-height: 500px;
      overflow: auto;
      width: 100%;
      z-index: 1000;
      padding: 4px 8px 8px 16px;

      .group-button {
        display: flex;
        gap: 4px;

        .button {
          margin: 10px 0;
          height: 43px;
          padding: 5px 5px;
        }
      }

      .list {
        max-height: 390px;
        overflow: auto;
      }

      &.small__block {
        width: 150%;
        //color: #75747d;
      }

      .menu__link {
        padding: 4px 0 4px 27px;
        background-color: #fff;
        border-radius: 5px;
        position: relative;
        border-top: none;

        &:before,
        &:after {
          content: "";
          width: 8px;
          height: 1px;
          background-color: black;
          position: absolute;
          top: 15px;
          transition: all 0.4s ease 0s;
          border-radius: 5px;
        }

        &:before {
          transform: rotate(-40deg);
          left: 5.7px;
        }

        &:after {
          transform: rotate(40deg);
          left: 0px;
        }

        &.arrow:before {
          transform: rotate(40deg);
        }

        &.arrow:after {
          transform: rotate(-40deg);
        }

        &:hover {
          //font-weight: 600;
          transition: 0.1s ease;
          color: #616161;
        }

        &.activeLink {
          font-weight: 600;
          color: #444444;
        }
      }
    }

    .submenu__list {
      width: 100%;
      display: none;
      position: relative;
      padding: 5px 0 0 0;
      background-color: #fff;
      color: black;

      .button__all {
        font-weight: 400;
        font-size: 15px;
        padding: 8px 24px;
        border-radius: 8px;
        color: #ffffff;
        margin-left: 27px;
        background-color: #4eb2ff;

        &:hover {
          cursor: pointer;
        }
      }

      &.active {
        display: block;
      }

      .submenu__link {
        display: flex;
        padding: 4px 30px;
        background-color: #fff;
        gap: 4px;
        //grid-template-columns: 20px 1fr;

        span {
          pointer-events: all;
        }

        .ui-input {
          min-width: 18px;
          min-height: 18px;
          width: 18px;
          height: 18px;
        }

        &:hover {
          background-color: #e7e7e7;
          transition: 0.1s ease;
          border-radius: 4px;
        }
      }
    }

    .without_group {
      padding: 4px 4px;
      //display: grid;
      display: flex;
      background-color: #fff;
      //grid-template-columns: 20px 1fr;
      gap: 5px;

      .ui-input {
        min-width: 18px;
        min-height: 18px;
        width: 18px;
        height: 18px;
      }

      &:hover {
        background-color: #e7e7e7;
        transition: 0.1s ease;
        border-radius: 4px;
      }
    }

    .mobileVersion {
      position: relative;
    }

  }
}
</style>
