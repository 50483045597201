<template>
  <section
      id="ite"
      :class="['tariffs', { scroll_stoper: isSafari }]"
  >
    <div class="title">{{ tariffs.title }}</div>
    <ul>
      <li
          v-for="card of tariffs.list"
          :key="card"
          :class="{ alt: card?.alt,}"
      >
        <div v-if="card.lines==true" class="line">В розробці</div>
        <div  :class="{ bluer: card?.bluer,}" class="info">
          <div>
            <div class="name">{{ card.name }}</div>
            <div class="price">
              <span>${{ card.price * (card?.isYear ? 10 : 1) }}</span>
              <span v-show="card.isUser">/ user</span>
            </div>
            <div class="menu">
              <div
                  :class="{ active: !card?.isYear }"
                  @click="card['isYear'] = false"
              >
                {{ tariffs.month }}
              </div>
              <div
                  :class="{ active: card?.isYear }"
                  @click="card['isYear'] = true"
              >
                {{ tariffs.year }}
              </div>
            </div>
          </div>
          <div class="points">
            <div
                v-for="point of card.points"
                :key="point"
            >
              <Icon
                  :name="point?.info ? 'info' : 'plus'"
                  :style="`${
                                        point?.info ? '--w: 24px; --h: 20px;' : '--size: 20px; --w: 21px;'
                                    } margin: 0 8px 0 0;`"
                  v-show="!point?.hideIcon"
              />
              <span>{{ point.label }}</span>
            </div>
          </div>
        </div>
        <div  class="footer">
          <p
              v-html = "card?.footer">
          </p>

        </div>
      </li>
    </ul>
    <div
        class="abe"
        style="margin: 0 0 24px 0"
    >
      {{ tariffs.abeText }}
    </div>
    <div class="just-in-time">
      <div class="label">JUST IN TIME</div>
      <div
          class="description"
          style="margin: 16px 0 40px 0"
      >
        {{ tariffs.justInTime.text }}
      </div>
      <ol>
        <li
            v-for="point of tariffs.justInTime.list"
            :key="point"
        >
          <div
              class="price"
              :class="{ bluer: point?.bluer,}"
          >${{ point.price }}</div>
          <div
              :class="{ bluer: point?.bluer,}"
              class="text">{{ point.text }}</div>
          <div v-if="point.lines==true" class="line">В розробці</div>
        </li>

      </ol>
      <div
          class="footer"
          style="margin: 40px auto 0 auto"
      >
        {{ tariffs.justInTime.footer }}
      </div>
    </div>
    <div
        class="abe f"
        style="margin: 24px 0 0 0"
    >
      {{ tariffs.justInTime.abeText }}
    </div>
  </section>
</template>
<script>


export default {
  name: "TariffS",
  data: () => ({
    tariffs: {
      title: 'ТАРИФИ ДЛЯ КОЖНОГО',
      month: 'в МІСЯЦЬ',
      year: 'В рік',
      list: [
        {
          name: 'Free',
          price: 0,
          footer: '<a style="text-decoration: none; color:white;" href="/register">Спробувати<a/>',
          points: [
            {label: 'публікуй до 4х проєктів'},
            {label: 'отримуй рейтинг та відгуки по  проєктах (до 4х проєктів)'},
            {label: 'дивись профілі без контактів'},
            {label: 'моніторь аналітику твоєї діяльності на платформі'}
          ],
          alt: true
        },
        {
          name: 'Optimum',
          price: 5,
          footer: '<a style="text-decoration: none; color:white;" href="/register">Спробувати безкоштовно 3 місяці<a/>',
          points: [
            {label: 'все що у пакеті “Free”', hideIcon: true},
            {label: 'публікуй проєкти необмежено'},
            {label: 'отримуй рейтинг та відгуки по реалізованих проєктах'},
            {label: 'дивись профілі з контактами'},
            {label: 'надсилай запити на відгук'},
          ],
          alt: true
        },
        {
          name: 'Premium',
          price: 10,
          footer: 'Дізнатися першим про відкриття',
          points: [
            {label: 'все що у пакеті “Optimum”', hideIcon: true},
            {label: 'здійснювати AI підбір для порівняння учасників по власним критеріям'}
          ],
          lines: true,
          bluer: true,
        },
        {
          name: 'Enterprise',
          price: 5,
          footer: 'Дізнатися першим про відкриття',
          isUser: true,
          points: [
            {label: 'все що у пакеті “Premium”', hideIcon: true},
            {
              label: 'отримуй пакет PREMIUM за ціною пакету OPTIMUM при умові оформлення підписки на команду від 5ти користувачів',
              info: true
            }
          ],
          lines: true,
          bluer: true,
        },
      ],
      abeText: 'користуйтесь лише необхідними можливостями та лише при потребі',
      justInTime: {
        text: 'тариф передбачає усі можливості пакету “Free” та дає змогу використовувати більше можливостей за додаткову оплату',
        list: [
          {
            price: 0.5,
            text: ' розширюй власне портфоліо - додавай реалізовані проєкти за додаткову оплату за кожен проєкт',
          },
          {
            price: 0.5,
            text: 'відкрий цікавий тобі контакт у профілі учасника або проєкта за додаткову оплату кожного контакта',
          },

          {
            price: 0.5,
            text: 'направляй запит на отримання відгуку та рейтингу за додаткову оплату за кожен запит'
          },
          {
            price: 1,
            text: 'приймай участь в конкурсах на реалізацію проєктів за додаткову оплату кожного цікавого тобі конкурса',
            lines:true,
            bluer: true,
          },
          {
            price: 2,
            text: 'підбирай та порівнюй учасників за власними критеріями за допомогою AI PRO Connect підбору - за додаткову оплату по кожному підбору',
            lines:true,
            bluer: true,
          }
        ],
        footer: 'Спробувати безкоштовно',
        abeText: 'до 24.02.2024 року всім підписникам надається можливість користуватись платформою PRO Connect безкоштовно'
      },
    },


  }),


}


</script>


<style scoped lang="scss">
section.tariffs {
  margin: 50px auto;
  max-width: 1240px;
  position: relative;

  .title {
    color: #3a464e;
    font-size: calc(3vw + 1vh + 0.5vmin);
    text-align: center;
    line-break: 66px;
    text-transform: uppercase;
  }

  ul {
    display: grid;
    margin: 48px 0;
    font-weight: 300 !important;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    li {
      padding: 40px 40px 20px 40px;
      color: #3a464e;
      border-radius: 25px;
      border: 1.5px solid #3a464e;
      position: relative;
      overflow: hidden;

      &.alt {
        color: #fff;
        border: 1.5px solid #2aa9e0;
        background-color: #2aa9e0;



        .info {
          .menu {
            border: 1.5px solid #fff;
            div {
              color: #fff;
              &.active {
                color: #2aa9e0;
                background-color: #fff;
              }
            }

          }

          .proconnect-icon {
            --color: #fff;
          }
        }

        .footer {
          color: #fff;
          border-color: white;
        }
      }

      .info {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;

        .name {
          font-size: 13px;
          font-weight: 800;
          text-transform: uppercase;
          line-height: 16.9px;
          letter-spacing: 0.07em;
        }

        .price {
          display: flex;
          margin: 7px 0 30px 0;
          align-items: flex-start;

          span:nth-child(1) {
            font-size: 85px;
            line-height: 89.25px;
          }

          span:nth-child(2) {
            margin: 0 0 0 8px;
            font-size: 13px;
            line-height: 16.9px;
          }
        }

        .menu {
          display: flex;
          padding: 3px;
          max-width: max-content;
          border-radius: 50px;
          border: 1.5px solid #2aa9e0;
          user-select: none;

          div {
            cursor: pointer;
            padding: 5px 12px;
            color: #2aa9e0;
            font-size: 13px;
            font-weight: 800;
            text-transform: uppercase;
            line-height: 16.9px;
            letter-spacing: 7%;
            white-space: nowrap;
            border-radius: 50px;
            transition: 0.2s;

            &.active {
              color: #fff;
              background-color: #2aa9e0;
            }
          }
        }

        .points {
          display: flex;
          flex-direction: column;

          div {
            display: flex;
            margin: 0 0 14px 0;
            align-items: flex-start;

            &:last-child {
              margin: 0;
            }

            span {
              font-size: 13px;
              line-height: 16.9px;
            }
          }
        }
      }

      .footer {
        //margin: 24px 0 0 0;
        //width: 100%;
        color: #3a464e;
        font-size: 18px;
        text-align: center;
        line-height: 27px;
        cursor: pointer;
        border: 1.5px solid #2aa9e0;
        border-radius: 50px;
        padding: 3px;
        width: 400px;
        margin: 24px auto 0 auto;
      }
    }
  }

  .line {
    position: absolute;
    top: 18px;
    right: -38px;
    transform: rotate(47deg);
    font-weight: 500;
    --color: #FF6F6F;
    padding: 8px 32px;
    color: var(--background-primary);
    font-family: var(--font-primary);
    background-color: var(--color);

  }

  .abe {
    color: #3a464e;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    line-height: 27px;
  }

  .just-in-time {
    padding: 40px 40px 20px 40px;
    color: #fff;
    border-radius: 25px;
    background-color: #2aa9e0;

    .label {
      font-size: 30px;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
      line-height: 31.5px;
    }

    .description,
    .footer {
      font-size: 18px;
      font-weight: 300;
      text-align: center;
      line-height: 27px;
    }

    .footer{
      border:1px solid white;
      border-radius: 50px;
      width: 400px;
      margin: 24px auto 0 auto;
      text-align: center;
      cursor: pointer;
      padding: 3px;
    }
    ol {
      display: flex;
      justify-content: space-between;

      li {
        margin: 0 24px 0 0;
        padding: 0 24px 0 0;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        &::after {
          content: " ";
          width: 1px;
          height: 60px;
          position: absolute;
          top: 50%;
          right: 0px;
          background-color: #ffffff33;
          transform: translateY(-50%);
        }

        &:last-child {
          margin: 0;
          padding: 0;

          &::after {
            display: none;
          }
        }

        .price {
          margin: 0 0 12px 0;
          font-size: 30px;
          font-weight: 500;
        }

        .text {
          font-weight: 300;
          font-size: 13px;
          line-height: 16.9px;
        }
      }
      .line {
        position: absolute;
        top: 18px;
        right: -38px;
        transform: rotate(47deg);
        font-weight: 500;
        --color: #FF6F6F;
        font-size: 12px;
        padding: 2px 32px;
        color: var(--background-primary);
        font-family: var(--font-primary);
        background-color: var(--color);

      }
    }
  }
  .bluer{
    opacity: 0.7;
  }
}

</style>