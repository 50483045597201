<template>
  <section id="questions" :class="['questions', { scroll_stoper: isSafari }]">
    <div class="title">{{ questions.title }}</div>
    <EnteringEmail
        v-if='isPopupVisible === true'
        @closePopup="closePopup">
    </EnteringEmail>
    <ul>
      <li
          v-for="question of questions.list"
          :key="question"
          :class="{ active: question['open'] }"
      >
        <div
            class="header"
            @click="question['open'] = !question['open']"
        >
          <div class="label">{{ question.label }}</div>
          <Icon
              name="plus-alt"
              style="--size: 28px"
          />
        </div>
        <transition name="question-height">
          <div
              class="text"
              style="--max-height: 169px; --d: 0.3s"
              v-show="question['open']"
          >
            <p
                v-html="question.text"
                id="mobile_version"
                @click="this.showPopup"
            ></p>
<!--            {{ question.text }}-->
          </div>
        </transition>
      </li>
    </ul>

  </section>
</template>
<script>
import EnteringEmail from "@/components/Modals/EnteringEmail.vue";

export default {
  name: "FAQ",
  components: {EnteringEmail},
  data: () => ({
    isPopupVisible: "false",
    questions: {
      title: 'ЧАСТІ ЗАПИТАННЯ',
      list: [
        {
          label: 'Навіщо використовувати PRO Connect, якщо є Facebook та LinkedIn?',
          text: 'PRO Connect - це не лише платформа - це професійна спільнота на будівельному ринку, що створює Екосистему в будівництві. PRO Connect надає значно більше технічних можлівостей для презентації власних проєктів, для отримання рейтингів та відгуків, для формування команд реалізації проєктів. З можливостями PRO Connect можна ознайомитися за посиланням'
        },
        {
          label: 'Що робити, якщо забув пароль від аккаунта?',
          text: 'Відновлення паролю здійснюється через електронну пошту, що була зазначена при реєстрації облікового запису.  '
        },
        {
          label: 'Як забезпечується безпека моїх даних?',
          text: 'Безпека даних забезпечується завдяки застосуванню server Digital Ocean та Google Cloud Storage. Данні рішення з забезпечення безпеки даних застосовуються провідними світовими компаніями, наприклад: Slack, Gitlab, Cloudways, Ghost. Деталі забезпечення конфіденційності персональних даних викладені в <a style="color:#4EB2FF" href="/privacy">Політиці конфіденційності</a> .'
        },
        {
          label: 'У вас є додаток?',
          text: 'На даний момент додаток розробляється і обов’язково буде доступний до завантаження після випуску. <button id="text"  style="cursor:pointer;font-size:16px;font-family: var(--font-primary);background: none;color:#4EB2FF"; ">Дізнатися першим про випуск мобільного додатку.<button/>'
        },
        {
          label: 'Як отримати відгук та рейтинг від замовника, партнера чи учасника команди?',
          text: '<ul><li>1. Зареєструйтеся на платформі PRO Connect;</li><li> 2. Створіть власне портфоліо з картками проєктів, в яких ви приймали участь і по яких бажаєте отримати відгук та рейтинг;</li><li>3. Якщо ваш замовник зареєстрований на платформі, направте запит на відгук замовнику проєкту натиснувши кнопку «Запросити відгук».</li><li>4. Якщо ваш замовник ще не зареєстрований на платформі, направте запит на відгук натиснувши кнопку «Надійслати запит на відгук через email».</li></ul>'
        },
        {
          label: 'Як перевірити правдивість відгуків та рейтингів учасників платформи?',
          text: 'В кожному відгуці по реалізованому проєкту є посилання на особу, яка надала даний відгук. Можно зв`язатись з данною особою через контакти для зв’язку або соціальні мережі, що зазначені в профілі учасника. Якщо у учасника PRO Connect є статус Verified, це означає, що всі дані картки учасника перевірені адміністрацією PRO Connect.  '
        },
        {
          label: 'Як повідомити про неправдивий відгук, рейтинг, неправдиву інформацію в портфоліо?',
          text: 'Для цього необхідно направити запит на адміністрацію платформи через <a style="color:#4EB2FF" href="/feedback">форму зворотнього зв’язку<a/> і ми перевіримо деталі цього відгуку.'
        },
        {
          label: 'Як отримати особливі статуси на платформі?',
          text: 'На платформі PRO Connect можно отримати декілька різних статусів: <ul> <li>• Статус «PRO PM Family». Цей статус можуть отримати учасники, які пройшли навчання в школі PRO PM Construction. Для отримання статусу, учасник надсилає через <a style="color:#4EB2FF" href="/feedback">форму зворотнього зв’язку<a/> запит на даний статус адміністратору платформи PRO Connect.</li> <li>• Статус «Verified». Цей статус означає, що всі дані в профілі даного учасника платформи перевірені та їх правдивість підтверджена адміністрацією PRO Connect. Для отримання даного статусу учасник надсилає через <a style="color:#4EB2FF" href="/feedback">форму зворотнього зв’язку<a/> запит на даний статус адміністратору платформи PRO Connect.</li> <ul/>'
        },
        {
          label: 'Інші питання?',
          text:'Залишилися інші питання, напишить через <a style="color:#4EB2FF" href="/feedback">форму зворотнього зв’язку<a/>  і ми будемо раді відповісти. '
        },
      ]
    },


  }),
  methods:{
    showPopup(e) {
      let clickedElId = e.target.id;
      if(clickedElId === 'text'){
        this.isPopupVisible = true;
      }

    },
    closePopup() {
      this.isPopupVisible = false;
    },
  }
}
</script>


<style scoped lang="scss">
section.questions {
  margin: 50px auto;
  max-width: 1024px;
  position: relative;
  padding: 15px;
  @media (max-width: 500px){
    margin: 20px auto;
  }

  .title {
    margin: 0 0 42px 0;
    font-size: calc(3vw + 1vh + 0.5vmin);
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 500px){
      margin: 0 0 22px 0;
    }
  }

  ul {
    li {
      height: max-content;
      border-bottom: 1.5px solid #3a464e26;
      transition: all 0.2s;

      &.active {
        .header {
          .proconnect-icon {
            transform: rotate(45deg);
          }
        }
      }

      .header {
        cursor: pointer;
        display: flex;
        gap:10px;
        padding: 24px 0;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 500px){
          padding: 12px 0;
        }
        .label {
          color: #3a464e;
          font-size: 28px;
          font-weight: 400;
          line-height: 31.5px;
          @media (max-width: 989px){
            font-size: 24px;
          }
          @media (max-width: 500px){
            font-size: 20px;
          }
        }

        .proconnect-icon {
          transition: 0.2s;
        }
      }

      .text {
        margin: 12px 0 24px;
        max-width: 100%;
        color: #3a464e;
        font-size: 16px;
        line-height: 27px;
        transition: all 0.2s;
        padding-right: 25px;

        &.question-height-enter-active {
          --b: 32px;
          animation: Height 0.5s ease-out;
        }

        &.question-height-leave-active {
          --b: 24px;
          animation: Height 0.5s ease-out reverse;
        }

        @keyframes Height {
          from {
            margin: 0;
            max-height: 0px;
            opacity: 0;
          }

          50% {
            margin: 12px 0 var(--b);
          }

          to {
            margin: 12px 0 24px;
            max-height: 205px;
          }
        }
      }
    }
  }
}
</style>