import Button from "@/components/UI/Button";
import PlaceholderTextInput from "@/components/UI/PlaceholderTextInput";
import SelectInput from "@/components/UI/SelectInput";
import CheckboxInput from "@/components/UI/CheckboxInput";
import TextInput from "@/components/UI/TextInput";
import Loading from "@/components/UI/Loading";
import Skeleton from "@/components/UI/Skeleton";
import Icon from "@/components/UI/Icon";
import MultiRange from "@/components/UI/MultiRange";
import DropdownInput from "@/components/UI/DropdownInput";
import ToTop from "@/components/other/ToTop";

export default {
    Button,
    PlaceholderTextInput,
    SelectInput,
    CheckboxInput,
    TextInput,
    Loading,
    Skeleton,
    Icon,
    MultiRange,
    DropdownInput,
    TopInPage: ToTop,
};
