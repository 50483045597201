<template>
  <input
      class="ui-input"
      type="checkbox"
      @input="onInput"
      :checked="modelValue"
      :disabled="disabled"
      @change="handleChange"
  />
</template>
<script>
export default {
  emits: ['onChange', 'update:modelValue'],
  name: "UICheckboxInput",
  props: {
    modelValue: {type: Boolean, required: false, default: false},
    disabled: {type: Boolean, required: false, default: false},
  },
  methods: {
    onInput(e) {
      this.$emit("update:modelValue", e.target.checked);
    },
    handleChange(e) {
      this.$emit('onChange', e.target.checked);
    },
  },
};
</script>
<style lang="scss" scoped>
.ui-input {
  // -moz-appearance: none;
  // -webkit-appearance: none;
  // appearance: none;
  cursor: pointer;
  min-width: 24px;
  min-height: 24px;
  padding: 0;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.ui-input:checked {
}
</style>
