import $api from "@/http";

class AuthService {
  async login(email, password) {
    return await $api.post("/users/login/", { email, password });
  }

  async signUp(email, name, password, country) {
    return await $api.post("/users/signup/", { email, username: name, name, password, country });
  }

  async refreshToken(token) {
    return await $api.post("/users/refresh-login/", { refresh: token });
  }
  async passwordReset(email) {
    return await $api.post("/password-reset/", { email: email });
  }
}

export default new AuthService();
