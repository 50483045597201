<template>
    <i
        :class="`proconnect-icon i-${name}`"
        v-if="icon"
        :style="{
            '--size': size,
            '--w': w ? w : 'var(--size)',
            '--h': h ? h : 'var(--size)',
            '--icon': `url('${icon}')`,
        }"
    ></i>
    <Skeleton
        :style="`--size: ${size};`"
        v-else
    />
</template>

<script>
    export default {
        name: "ProconnectIcon",
        props: {
            name: { type: String },
            size: { type: String, default: "16px" },
            w: { type: String },
            h: { type: String },
        },
        data: () => ({
            icon: null,
        }),
        mounted() {
            let icon = new Image();
            icon.src = require(`@/assets/img/${this.name}.svg`);
            icon.onload = () => {
                this.icon = icon.src;
            };
        },
    };
</script>

<style lang="scss" scoped>
    i.proconnect-icon {
        --color: var(--text-primary);
        display: inline-block;
        min-width: var(--w);
        min-height: var(--h);
        background-color: var(--color);
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-image: var(--icon);
        mask-image: var(--icon);
    }

    .ui-skeleton {
        padding: 0 !important;
        max-width: var(--size) !important;
        min-width: var(--size) !important;
        max-height: var(--size) !important;
        min-height: var(--size) !important;
        border-radius: 50% !important;
    }
</style>
