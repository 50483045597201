<template>
  <label
      :class="[
            'ui-input',
            this.class,
            {
                disabled,
                disabledActive: modelValue,
                'error-input': error,
                [`img-${img[1] || 'left'}`]: Boolean(img[0]),
                'hide-label': hideLabel,
            },
        ]"
  >
    <div
        class="ui-input-image"
        v-if="img[0]"
        :style="{ '--image': `url('${img[0]}')`, cursor: img[2] ? 'pointer' : 'default' }"
        @click="img[2] ? img[2]($event) : img[2]($event)"
    ></div>
    <input
        v-if="typeTag === 'input'"
        :class="{ active: active || type == 'number', rangeType: isRange,  bigPaddings}"
        ref="input"
        :value="text"
        @input="onInput($event)"
        :type="type"
        :required="true"
        :disabled="disabled"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
        :min="minVal"
        :max="maxVal"
        :maxlength="max"
    />
    <textarea
        v-if="typeTag === 'textarea'"
        :class="{ active}"
        ref="input"
        :value="text"
        :style="{ '--height': `${rows * 24}px` }"
        @input="
                onInput($event);
                getLines();
            "
        :type="type"
        :disabled="disabled"
        :required="true"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
        :maxlength="max"
    ></textarea>
    <div
        class="ui-input-placeholder">
      <span>{{ placeholder }}</span>
    </div>
  </label>
</template>

<script>
export default {
  name: "UIPlaceholderTextInput",
  props: {
    desktopVersion: {
      type: Boolean,
      default: true,
    },
    placeholder: {type: String, required: false, default: ""},
    typeTag: {type: String, default: "input"},
    type: {type: String, required: false, default: "text"},
    img: {type: Array, default: () => [false, "left", false]},
    class: {type: String, required: false, default: ""},
    modelValue: {type: [String, Number], required: false},
    disabled: {type: Boolean, required: false, default: false},
    isRange: {type: Boolean, required: false, default: false},
    error: {type: Boolean, default: false},
    max: {type: Number, default: 999999999999},
    maxVal: {type: Number, default: 999999999999},
    minVal: {type: Number, default: -999999999999},
    hideLabel: {type: Boolean, default: false},
    blueBorder: {type: Boolean, default: false},
    bigPaddings: {type: Boolean, default: false},

  },
  data: () => ({
    rows: 0,
    text: "",
    active: false,
    timeout: undefined,

  }),
  watch: {
    modelValue(newValue) {
      this.text = newValue;
    },
    text(newValue) {
      this.active = newValue && `${newValue}`.length > 0;
    },
  },
  methods: {
    onInput(event) {
      this.text = event.target.value;
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }
      this.timeout = setTimeout(() => {
        let res = this.text;
        if (this.type == "number") {
          res = parseFloat(res);
          if (res > this.maxVal) {
            res = this.maxVal;
          }

          if (this.minVal > res) {
            res = this.minVal;
          }

          if (res != this.text) {
            this.text = res;
          }
        }
        this.$emit("update:modelValue", res);
      }, 400);
    },
    getLines() {
      let lines = `${this.text || ""}`?.match(/\n/g)?.length || 0;
      this.rows = lines > 16 ? 16 : lines;
    },
  },
  mounted() {
    this.text = this.modelValue;
    this.getLines(this.modelValue);
  },
};
</script>

<style lang="scss" scoped>
.ui-input {
  --bg: var(--background-primary);
  --bg-label: var(--background-primary);
  //--input-h: 48px;
  --radius: 5px;
  --img-bg: var(--background-darked);
  --label-left: 12px;
  --h: 100%;
  --b-color: #dddce8;
  --f-size: 16px;
  display: block;
  position: relative;
  width: 100%;
  max-height: var(--input-h);
  min-height: var(--input-h);


  &-image {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 7px;
    border-radius: 50%;
    background-size: contain;
    //background-position: center;
    background-image: var(--image);
    //background-color: var(--img-bg);
    //background-repeat: no-repeat;
  }

  &.img-left {
    .ui-input-image {
      left: 8px;

      & + input {
        //padding: 10px 24px 10px 48px;

        & + .ui-input-placeholder {
          left: 34px;
        }

        &:focus + .ui-input-placeholder,
        &.active + .ui-input-placeholder {
          left: var(--label-left);
        }
      }
    }
  }

  &.img-right {
    .ui-input-image {
      right: 16px;

      & + input {
        padding: 10px 48px 10px 24px;
      }
    }
  }

  &.error-input {
    input,
    textarea {
      border: 1px solid var(--red);
    }

    .ui-input-placeholder {
      color: var(--red);
    }
  }

  &.hide-label {
    input,
    textarea {
      &:focus,
      &.active {
        & + .ui-input-placeholder {
          font-size: 16px !important;
          top: 50% !important;
          transform: translateY(-50%) !important;
          opacity: 0 !important;
        }
      }
    }
  }

  input, textarea {
    padding: 10px 14px;
    width: 100%;
    height: var(--h);
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 400;
    font-family: var(--font-primary) !important;
    line-height: 120%;
    border-radius: var(--radius);
    border: 1px solid #dddce8;
    background: var(--bg);
    transition: all 0.2s;
    resize: vertical;

    @media (max-width: 1024px) {
      border-radius: 6px;
      //height: 44px;
    }


    &:focus,
    &.active {
      color: #000 !important;
      background-color: white;

      & + .ui-input-placeholder {
        top: -7px;
        font-size: 12px;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        background-color: var(--bg);

        &::before {
          background: var(--bg-label);
        }
      }
    }
  }

  input {
    min-height: var(--input-h) !important;
  }

  input[type="date"] {
    color: transparent;
  }

  textarea {
    min-height: 96px;
    height: var(--height);
  }

  textarea + &-placeholder {
    top: 16px;
    transform: translateY(0);
  }

  &-placeholder {
    pointer-events: none;
    padding: 0 4px;
    position: absolute;
    top: 50%;
    left: var(--label-left);
    color: #75747d; //var(--text-secondary);
    font-size: var(--f-size);
    white-space: nowrap;
    transform: translateY(-50%);
    transition: 0.2s;
    user-select: none;
    -webkit-transform: translateY(-50%);
    //z-index: 2;
    @media (max-width: 1024px) {
      color: #6F6F6F;
      font-weight: 600;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }

    &::before {
      content: "";
      width: 105%;
      min-height: 1px;
      position: absolute;
      top: 7px;
      left: -2.5%;
      z-index: 1;
    }

    span {
      position: relative;
      z-index: 2;
    }
  }

  &.disabled {
    --bg: rgba(239, 239, 239, 0.5);

    &.disabledActive {
      .ui-input-placeholder {
        top: -7px;
        left: 10px;
        font-size: 12px;
        background-color: var(--bg);

        &::before {
          background: var(--bg-label);
        }
      }
    }
  }

  .rangeType {
    width: 100%;
    padding: 0 16px;
    font-size: 14px;
    height: 20px;
  }

  .bigPaddings {
    padding: 7.5px 38px;
  }
}
</style>
