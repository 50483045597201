<template>
  <button
    :stroke="stroke"
    :class="['ui-button', this.class]"
    :disabled="disabled"
  >
    <div>
      <slot></slot>
    </div>
    <div v-show="btnLoading">
      <Loading style="width: 20px; height: 20px; padding: 10px 0" />
    </div>
  </button>
</template>

<script>
  export default {
    name: "ButtonAdd",
    props: {
      btnLoading: { type: Boolean, default: false },
      stroke: { type: Boolean, required: false, default: false },
      disabled: { type: Boolean, required: false, default: false },
      class: { type: String, required: false, default: "" },
      paddingsForButton: { type: Boolean, default: false },
    },
  };
</script>

<style lang="scss" scoped>
  .ui-button {
    // margin-top: 16px;
    cursor: pointer;
    text-align: center;
    padding: 16px 10px;
    width: 100%;
    border: 1px solid #dddce8;
    border-radius: 6px;
    background-color: var(--background-primary);
    gap: 12px;
    font-size: 16px;
    color: #6f6f6f;
    font-family: var(--font-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 430px) {
      padding: 14px 10px;
    }

    &:hover {
    }

    &:active {
    }
    &:focus {
      box-shadow: 0 0 10px -3px #0000003e;
    }

    &:disabled {
      background: #b4b4b4 !important;
    }
  }
</style>
