<script>
    export default {
        data() {
            return {
                showButton: false,
            };
        },
        // props: {
        //     showButton: { default: true, type: Boolean },
        // },
        watch: {
            "$route.path"() {
                if (this.showButton) this.goToTop();
            },
        },
        methods: {
            handleScroll() {
                let element = document.getElementById("app");
                this.showButton = element.scrollTop > 80;
            },
            goToTop() {
                //setTimeout(() => {
                let element = document.getElementById("app");
                if (element) element.scrollTo(0, 0);
                //}, 0);
            },
        },
        mounted() {
            let element = document.getElementById("app");
            if (element) element.addEventListener("scroll", this.handleScroll);
        },
        beforeUnmount() {
            let element = document.getElementById("app");
            if (element) element.removeEventListener("scroll", this.handleScroll);
        },
    };
</script>

<template>
    <div class="to-top">
        <transition
            enter-active-class="fade-show"
            leave-active-class="fade-hide"
        >
            <button
                v-show="showButton"
                class="to-top--button"
                @click="goToTop"
            ></button>
        </transition>
    </div>
</template>

<style lang="scss" scoped>
    .to-top {
        position: relative;
        width: 0px;
        height: 0px;

        &--button {
            position: fixed;
            bottom: 40px;
            right: 20px;
            --color: #4eb2ff;
            --color-alt: #2194c5;
            cursor: pointer;
            width: 40px;
            height: 40px;

            color: var(--background-primary);
            font-size: 16px;
            font-family: var(--font-primary);
            text-align: center;
            border-radius: 50%;
            background-color: var(--color);
            transition: all 0.2s;
            display: flex;
            justify-content: center;
            z-index: 21;
            box-shadow: 0 6px 6px 0 #00000040;
            &::after {
                content: "";
                position: absolute;
                width: 15px;
                border: 2px solid white;
                left: 17px;
                top: 17px;
                z-index: 22;
                transform: rotate(45deg);
            }
            &::before {
                content: "";
                position: absolute;
                width: 15px;
                border: 2px solid white;
                left: 9px;
                top: 17px;
                z-index: 22;
                transform: rotate(-45deg);
            }
        }
    }
</style>
