<template>
    <button
        :stroke="stroke"
        :class="['ui-button', this.class]"
        :disabled="disabled"
    >
        <div v-if="true">
            <Loading
                style="width: 20px; height: 20px; padding: 10px 0"
                v-show="btnLoading"
            />
        </div>
        <div style="display: flex; gap: 5px;align-items: center">
            <slot></slot>
        </div>
    </button>
</template>

<script>
    export default {
        name: "UIButton",
        props: {
            btnLoading: { type: Boolean, default: false },
            stroke: { type: Boolean, required: false, default: false },
            disabled: { type: Boolean, required: false, default: false },
            class: { type: String, required: false, default: "" },
            paddingsForButton: { type: Boolean, default: false },
        },
        //  watch: {
        //      disabled(newValue) {
        //          this.$el[newValue ? "removeAttribute" : "setAttribute"]("disabled", newValue);
        //      },
        //  },
        //  mounted() {
        // this.$el[!this.disabled ? "removeAttribute" : "setAttribute"]("disabled", this.disabled);
        //  },
    };
</script>

<style lang="scss" scoped>
    .ui-button {
        --color: #4eb2ff;
        --color-alt: #2194c5;
        cursor: pointer;
        padding: 12px 32px;
        width: 100%;
        color: var(--background-primary);
        font-size: 16px;
        font-family: var(--font-primary);
        text-align: center;
        border-radius: 6px;
        background-color: var(--color);
        transition: all 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;


        &:active {
            border: 1px solid #2a9df4;
        }
        &:focus {
            background-color: #43a7f5;
            transition: all 0.2s ease 0s;
        }

        //&:not(&[stroke="true"], &.alt):hover {
        //  background-color: var(--color-alt);
        //}

        &[stroke="true"],
        &.alt {
            color: var(--color);
            border: 1px solid var(--color);
            background-color: transparent;
        }

        &:disabled {
            background: #b4b4b4 !important;
        }
    }
    .button__image {
        padding: 12px 32px 12px 17px;
        display: flex;
        gap: 16px;
    }
</style>
