<template>

  <div class="policy">
    <div class="policy_container">
      <h2 class="policy_title">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ БУДІВЕЛЬНОЇ ПЛАТФОМИ «PRO Connect»</h2>
      <div class="policy_body body">
        <div class="body_block">
          <h3 class="body_title">ЗАГАЛЬНА ІНФОРМАЦІЯ</h3>
          <div class="body_list">
            <p> 1. Платформа PRO Connect (надалі - «Платформа» або «PRO Connect») з усією
              відповідальністю
              та увагою
              ставиться до ваших персональних даних, які отримані від вас як Користувача Платформи.
            </p>
            <p>2. Будь ласка, ознайомтеся з нашою Політикою конфіденційності (надалі -
              «Політика»),
              розробленою з
              урахуванням Закону України «Про Захист персональних даних», щоб дізнатися які ваші персональні дані ми
              обробляємо, в яких цілях, яким чином і на якій підставі.
            </p>
          </div>
        </div>
        <div class="body_block">
          <h3 class="body_title">ЯКА СФЕРА ДІЇ ПОЛІТИКИ?</h3>
          <div class="body_list">
            <p> 3. Дана Політика діє для всіх інтернет-сервісів Платформи (надалі - «Сервіси», а кожен
              окремо – «Сервіс»), що надаються ТОВ «ПРО ПІ ЕМ» (Україна, 02121, місто Київ, Харківське шосе, 201-203, 1А
              літера Ю) (надалі – «Розпорядник») стосовно використання вашої персональних даних, які збираються протягом
              відвідання сайту <a href="www.proconnect.construction">www.proconnect.construction</a> (надалі – «Сайт»),
              усіх його порталів та мобільного додатку PRO
              Connect (надалі – «Мобільний додаток»).
            </p>
            <p>4. Використання Платформи (Сервісів, Сайту та Мобільного додатку) означає:</p>
            <ul>
              <li>4.1 Вашу повну і безумовну згоду як Користувача з цією Політикою та вказаними в ній цілями та
                умовами обробки ваших персональних даних;
              </li>
              <li>4.2 Вам зрозумілі ваші права, визначені Законом України «Про захист персональних даних»;</li>
              <li>
                4.3 Вашу згоду з тим, що термін обробки ваших персональних даних є безстроковим.
              </li>
            </ul>
            <p> 5. Платформа призначена для користування особами, що старші за 16 років. Ми не обробляємо персональні
              дані
              дітей, що молодші за 16 років. Якщо Ви дізналися, що дитина користується Платформою, не дивлячись на
              передбачені заборони та обмеження, будь ласка, повідомте нам і ми негайно припинимо обробку і видалимо всі
              її дані.
            </p>
          </div>
        </div>
        <div class="body_block">
          <h3 class="body_title">ЯК ПРАЦЮЄ СЛУЖБА ПІДТРИМКИ?</h3>
          <div class="body_list">
            <p>6. З будь-яких питань, пов'язаних з обробкою Ваших персональних даних, будь ласка,
              звертайтеся до Менеджера по захисту персональних даних:
              <ul>
                <li>6.1 за електронною адресою: <a
                    href="mailto:spd@proconnect.construction">spd@proconnect.construction</a> або
                </li>
                <li>6.2 за поштовою адресою: Україна, 02121, місто Київ, Харківське шосе, 201-203, 1А літера Ю, для ТОВ
                  «ПРО
                  ПІ ЕМ» з поміткою «Менеджеру по захисту персональних даних».
                </li>
              </ul>
            </p>

          </div>
        </div>
        <div class="body_block">
          <h3 class="body_title">ЯКА МЕТА ЗБОРУ ТА ОБРОБКИ ДАНИХ?</h3>
          <div class="body_list">
            <p> 7. Метою збору, зберігання і обробки ваших персональних даних будь – яким способом є
              надання Вам, як Користувачу Платформи, персоналізованих Сервісів, у тому числі, але не обмежуючись:
              ідентифікація, аутентифікація, авторизація, відновлення та нагадування паролю, надсилання інформаційних
              матеріалів за підпискою Користувача, відповідей на запити та листи Користувача, таргетування рекламних
              матеріалів, а також для інших дій, що необхідні для належного надання нами та отримання вами заявлених
              Сервісів Платформи.
            </p>
            <p>8. Обробка персональних даних включає будь-які дії та/або сукупність дій, які пов'язані
              зі збором, реєстрацією, накопиченням, зберіганням, адаптацією, зміною, оновленням, використанням та
              поширенням (розповсюдженням, реалізацією, передачею), видаленням персональних даних Користувача з метою
              забезпечення роботи Сайту та Мобільного додатку та надання Сервісів Платформи.
            </p>
          </div>
        </div>
        <div class="body_block">
          <h3 class="body_title">ЯКІ ДАНІ ЗБИРАЮТЬСЯ?</h3>
          <div class="body_list">
            <p>9. У процесі користування Платформою, ми можемо отримувати наступні дані:
              <ul style="list-style: disc; padding-left: 18px">
                <li>прізвище, ім'я, по - батькові, логін;</li>
                <li> назву юридичної особи, логін;</li>
                <li> контактні дані: номер телефону, email, сторінки в соціальних мережах;</li>
                <li> облікові записи та / або сторінки соціальних мереж, які ви використовуєте для авторизації, пароль,
                  номер телефону або логін;
                </li>
                <li> область, населений пункт, вулиця, будинок, квартира, індекс, відділення пошти;</li>
                <li> реєстраційні дані юридичної особи;</li>
                <li> інформацію про професійну діяльність;</li>
                <li> фотографії;</li>
                <li> рекламні матеріали/оголошення;</li>
                <li> будь-які дані, крім зазначених, які Ви надаєте в зверненні, відгуці, коментарі, скарзі, заявці або
                  іншому запиті;
                </li>
                <li> прізвище, ім'я, по батькові, серія і номер паспорта, ким і коли виданий, відомості про
                  реєстрацію;
                </li>
                <li> реєстраційний номер облікової картки платника податків або ІПН;</li>
                <li> чати та повідомлення на Платформі;</li>
                <li> будь-які дії на Платформі;</li>
                <li> унікальний набір букв і чисел, який присвоюється при реєстрації (ідентифікатори);
                  IP-адреси, параметри обраного часового поясу, геолокація, тип пристрою, тип браузера, ідентифікатор
                  сеансу (взаємодія з Платформою, доменне ім'я сайту, з якого Ви перейшли на Платформу, функції, які Ви
                  використовуєте, і переглянуті сторінки Платформи, те, як Ви використовуєте Платформу, дату і час
                  запиту, та дії, які Ви вживаєте, якщо такі дії присутні), інші дані, які ми збираємо під час
                  користування Платформою, що дозволяють реалізовувати Сервіси, забезпечують технічне функціонування
                  Платформи, для статистичних досліджень, які дозволяють покращувати та підтримувати технічні служби
                  Платформи;
                </li>
                <li>файли "cookie";</li>
                <li>записи будь-якої кореспонденції, запити чи скарги, які Ви надсилаєте нам за допомогою Додатку/Сайту
                  або контактної електронної пошти, а також наші відповіді.
                </li>
              </ul>
            </p>
            <p>10. Звертаємо Вашу увагу, що надаючи доступ до персональних даних, Ви гарантуєте їх
              достовірність та точність, і зобов`язуєтеся повідомляти нас про їх зміни. Ви несете відповідальність за
              будь-який збиток, заподіяний Платформі або особі, яка відповідає за Платформу, а також будь-якому іншому
              Користувачеві або третій особі, в разі неточності або недостовірності Ваших персональних даних.
            </p>
          </div>
        </div>
        <div class="body_block">
          <h3 class="body_title">ЯК ЗМІНИТИ ТА ВИДАЛИТИ ДАНІ?</h3>
          <div class="body_list">
            <p> 11. Ви, як Користувач, можете в будь-який момент: </p>
            <p> 11.1 змінити (оновити, доповнити) надані вами персональні дані або їх частину, а також параметри їх
              конфіденційності, скориставшись функцією редагування картки учасника, картки проєкту, картки
              виробництва або картки продукту;
            </p>
            <p>11.2 видалити свої персональні дані шляхом входу в обліковий запис, у якому хочете видалити персональні
              дані, переходу на сторінку видалення даних і натиснути «Видалити». Якщо у вас не вийшло видалити
              особисті дані, будь ласка, зверніться в Службу підтримки.
            </p>
            <p>11.3 вимагати від Розпорядника повного видалення вашого аккаунту і всіх персональних даних з бази даних
              Сайту, звернувшись з відповідним запитом в Службу підтримки. Ваша заявка буде опрацьована протягом 10
              (десяти) робочих днів з моменту її отримання, а ваші дані будуть видалені. Однак ми можемо зберігати
              деякі з ваших персональних даних стільки часу, скільки це розумно необхідно для наших законних
              бізнес-інтересів або інших цілей, що відповідають вимогам законодавства, включаючи виявлення та
              запобігання шахрайству, а також для виконання наших юридичних зобов’язань, включаючи податкову,
              юридичну звітність та проходження аудиторських перевірок.
            </p>


          </div>
        </div>
        <div class="body_block">
          <h3 class="body_title">ЯК МИ ЗБЕРІГАЄМО ТА ЗАХИЩАЄМО ДАНІ?</h3>
          <div class="body_list">
            <p>12. Всі персональні дані зберігаються Розпорядником у дата-центрах, де розміщується
              обладнання, що забезпечує функціонування Платформи. Розпорядник приймає усі передбачені законодавством
              заходи для захисту ваших персональних даних, зокрема, обробка персональних даних здійснюється на
              обладнанні, що міститься в охоронюваних приміщеннях із обмеженим доступом.
            </p>
            <p>13. Розпорядник вживає всіх відповідних до сучасних технічних стандартів заходів,
              спрямованих на захист персональних даних Користувачів Платформи. Всередині Розпорядника доступ до
              персональних даних Користувачів надається лише тим співробітникам/підрядникам, для яких ця інформація є
              необхідною для покращення наших Сервісів, Сайту та Мобільного додатку. Ми гарантуємо, що всі такі
              співробітники/підрядники обмежені відповідними зобов’язаннями з дотримання конфіденційності щодо
              персональних даних, і будуть нести відповідальність у випадку порушення даних зобов’язань.
            </p>
          </div>
        </div>
        <div class="body_block">
          <h3 class="body_title">ЯК ОБРОБЛЯЮТЬСЯ ДАНІ ТРЕТІМИ ОСОБАМИ?</h3>
          <div class="body_list">
            <p>14. На Платформі (у Сервісах, на Сайті та у Мобільному додатку) можуть розміщуватися
              посилання на веб-сайти в мережі Інтернет, які можуть збирати власну інформацію, зберігати власні файли
              cookie тощо. Ми не контролюємо ці веб-сайти, а також політику конфіденційності цих веб-сайтів.
              Рекомендуємо Вам ознайомлюватися з політиками щодо конфіденційності конкретно на цих веб-сайтах.
            </p>
            <p>15. Політика не регулює і ми не несемо відповідальності за обробку Ваших персональних
              даних третіми особами, які не перебувають під нашим контролем або не пов'язані з нами договірними
              відносинами, навіть якщо Ви отримали доступ до сайтів, товарів або послуг цих осіб за допомогою Платформи.
              Наприклад, Ви побачили інформацію або рекламу будь-якої третьої особи на Платформі і перейшли на її сайт
              або знайшли продавця/постачальника робіт/послуг/товарів на Платформі і його контакти, але зв'язалися з ним
              самостійно і в обхід функціоналу Платформи або передали йому свої дані самостійно.
            </p>
            <p>16. Згода Користувача на обробку його персональних даних (п.4. цієї Політики) не вимагає від Розпорядника
              додаткових повідомлень при передачі його персональних даних третім особам відповідно до положень статті 21
              Закону України «Про захист персональних даних».
            </p>
            <p>17. Такими інтернет-ресурсами третіх осіб також можуть бути: </p>
            <p>17.1 системи збору статистики відвідувань Платформи (лічильники Google Analytics тощо);</p>
            <p>17.2 соціальні плагіни (блоки) соціальних мереж (наприклад, Facebook, Instagram тощо);</p>
            <p>17.3 системи банеропоказів (наприклад, AdRiver тощо);</p>
            <p>17.4 інші ресурси.</p>
            <p>18. Розпорядник має право передати персональні дані, базу персональних даних, до якої включені
              персональні
              дані Користувача, повністю або частково третім особам без повідомлення про це Користувача у наступних
              випадках: </p>
            <p>18.1 особам, у ведення, володіння або власність яких передано Платформу;</p>
            <p>18.2 особам, що є пов’язаними/афілійованими з Розпорядником;</p>
            <p>18.3 новому власнику та/або Розпоряднику Платформи для обробки з метою, передбаченою цієї Політикою
            </p>
            <p>18.4 іншим користувачам Платформи (як фізичним, так і юридичним особам), якщо відповідним Сервісом
              Платформи передбачено відповідний функціонал та це не суперечить положенням Закону України «Про захист
              персональних даних».
            </p>
          </div>
        </div>
        <div class="body_block">
          <h3 class="body_title">ПРИКІНЦЕВІ ПОЛОЖЕННЯ</h3>
          <div class="body_list">
            <p>19. Беручи до уваги наше бажання постійно покращувати та розширювати Сервіси Платформи та
              вдосконалювати роботу Сайту та Мобільного додатку, а також стрімкий розвиток інформаційних технологій та
              Інтернету, при обов`язковому збереженні належного рівня конфіденційності та безпеки ваших персональних
              даних, в дану Політику можуть вноситись зміни. В такому випадку ми зобов`язуємося публікувати оновлену
              версію Політики на цій же сторінці: «Політика конфіденційності».

            </p>
            <p>20. Якщо у Вас виникли питання стосовно цієї Політики або деталей її застосування, Ви
              можете зв’язатися з нашою Службою підтримкою за допомогою електронної адреси: <a
                  href="mailto:spd@proconnect.construction">spd@proconnect.construction</a> .
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "PrivacyPolicy",

}
</script>


<style scoped lang="scss">

.policy_container {
  max-width: 932px;
  margin: 0 auto;
  padding: 15px;
  margin-bottom: 38px;
  @media (max-width: 980px){
    padding: 20px;
  }

  .policy_title {
    margin-top: 32px;
    font-size: 24px;
    font-weight: bold;
    line-height: 150%;
    @media (max-width: 980px){
      margin-top: 22px;
      font-size: 22px;
    }
  }

  .body {
    .body_block {
      margin-top: 32px;
      @media (max-width: 980px){
        margin-top: 22px;
      }

      .body_title {
        font-size: 20px;
        font-weight: bold;
        @media (max-width: 980px){
          font-size: 18px;
        }
      }

      .body_list {
        font-size: 18px;
        line-height: 150%;
        @media (max-width: 980px){
          font-size: 16px;
        }


        p {
          margin-top: 16px;

        }
        a{

          text-decoration: none;
          color:#4EB2FF;
        }
        a:visited{
          color:#4EB2FF;
        }
      }
    }


  }
}
</style>