<template>
  <ModalBasic
      @closePopup="closePopup"
  >
    <template v-slot:header>
      <h3>Дізнатися першим про випуск мобільного додатку</h3>
    </template>
    <template v-slot:text>
      <PlaceholderTextInput
          :modelValue="email = user['email']"
          type="email"
          placeholder="Вкажіть ваш емейл"
          @update:modelValue="email = $event"
      />

    </template>
    <template v-slot:close>
      <ButtonExit class="close" @click="closePopup"><img src="@/assets/img/close2.svg"/></ButtonExit>
    </template>
    <template v-slot:buttonCancel>
      <Button @click="this.sentEmail"> Підтвердити</Button>
    </template>
  </ModalBasic>

</template>

<script>


import ModalBasic from "@/components/Modals/ModalBasic.vue";
import ButtonExit from "@/components/UI/ButtonExit.vue";
import PlaceholderTextInput from "@/components/UI/PlaceholderTextInput.vue";



export default {
  name: "EnteringEmail",
  components: {PlaceholderTextInput, ButtonExit,ModalBasic},
  data() {
    return {
      email: '',
    }
  },
  methods: {
    async sentEmail() {
      try {
        if (!this.email) return;
        await this.fetch('/api/v1/users/support-email/',
            {
              email: this.email,
            },
            "POST"
        );
        this.successfulSending = true;
      } catch (error) {
        console.error(error)
      }
      finally {
        this.$emit('closePopup');
      }
    },
    closePopup() {
      this.$emit('closePopup');
    }
  },
}

</script>


<style scoped lang="scss">

.close {
  cursor: pointer;
  border: none;
  padding: 1px;
  background-color: white;

  &:focus {
    transform: scale(1.1);
  }
}

.button-delete {
  padding: 12px 0px;

}
</style>