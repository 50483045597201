<template>
    <input
        :placeholder="placeholder"
        :value="modelValue"
        @input="onInput"
        :class="['ui-input', this.class]"
        :type="type"
        :disabled="disabled"
        :maxlength="max"
    >
</template>

<script>

export default {
    name: 'UIInput',
    props: {
        placeholder: { type: String, required: false, default: '' },
        type: { type: String, required: false, default: 'text' },
        class: { type: String, required: false, default: '' },
        modelValue: { type: String, required: true },
        max: { type: Number, default: 999999999999 },
        disabled: { type: Boolean, required: false, default: false }
    },
    methods: {
        onInput(e) {
            this.$emit('update:modelValue', e.target.value);
        }
    }
}

</script>

<style lang="scss" scoped>

.ui-input {
    width: 100%;
    height: 48px;
    padding: 10px 15px;
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    border-radius: 25px;
    border: 1px solid var(--header-third-alt);
}

</style>