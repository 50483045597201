<template>
    <TopInPage />
    <router-view v-slot="{ Component }">
        <MainWindows />
        <transition name="health">
            <Header v-show="!$route.meta.disableHeader" />
        </transition>
        <MainToolpic />
        <transition
            enter-active-class="fade-show"
            leave-active-class="fade-hide"
        >
            <component
                class="page"
                :is="Component"
            />
        </transition>
        <Footer v-show="!$route.meta.hideFooter" />
    </router-view>
</template>

<script>
    import { defineAsyncComponent } from "vue";

    export default {
        name: "App",
        components: {
            Header: defineAsyncComponent(() => import("@/components/Header/Header")),
            MainToolpic: defineAsyncComponent(() => import("@/components/Toolpic")),
            // Footer: defineAsyncComponent(() => import('@/components/Footer'))
            MainWindows: defineAsyncComponent(() => import("@/components/Windows")),
            Footer: defineAsyncComponent(() => import("@/components/Footer")),
        },
        watch: {
            "$route.meta.mandatoryAuth"(newValue) {
                if (newValue && !this.user?.id) {
                    this.router("/login");
                }
            },
            "$route.meta.noAuth"(newValue) {
                if (newValue && this.user?.id) this.router("/");
            },
        },
        async mounted() {
            if (localStorage.getItem("refresh_token")) {
                let data = await this.$store.dispatch("user/refreshToken", localStorage.getItem("refresh_token"));
                if (!data?.access) return;
                this.$store.dispatch("user/getUser");
                this.$store.commit("user/updateUser", { authorized: true });
            }

            this.loadListRoles();
        },
    };
</script>

<style lang="scss">
    @import "@/app.scss";
    .fade-show,
    .fade-hide {
        transition: 0.2s;
        opacity: 0 !important;
    }

    .page {
        transition: 0.2s;
    }

    .error-show,
    .height-enter-active {
        --max-height: 100px;
        --d: 0.2s;
        animation: Height var(--d) linear;
    }
    .error-hide,
    .height-leave-active {
        --max-height: 100px;
        --d: 0.2s;
        animation: Height var(--d) linear reverse;
    }

    @keyframes Height {
        from {
            padding: 0;
            max-height: 0px;
            opacity: 0;
        }

        to {
            max-height: var(--max-height);
        }
    }

    .error-text {
        padding: 12px 0;
        color: var(--red);
        text-align: center;
        border-radius: 5px;
        border: 1px solid var(--red);
    }

    *[scroll-hide] {
        scrollbar-width: none;

        &::-webkit-scrollbar-button {
            background-repeat: no-repeat;
            width: 0px !important;
            height: 0px !important;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 0px;
            border-radius: 0px;
            background-color: var(--background-darked);
        }

        &::-webkit-resizer {
            background-repeat: no-repeat;
            width: thin !important;
            height: 0px !important;
        }

        &::-webkit-scrollbar {
            width: 0px !important;
            background: var(--T);
        }
    }
</style>
