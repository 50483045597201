import { defineAsyncComponent } from "vue";

// * Menu Components
const Components = {
    // ? Review
    Review: defineAsyncComponent(() => import(`./windows/review/Main.vue`)),
    // ? Images
    UploadPhoto: defineAsyncComponent(() => import(`./windows/images/UploadPhoto.vue`)),
    ShowPhoto: defineAsyncComponent(() => import(`./windows/images/ShowPhoto.vue`)),
    // ? Page
    ViewDoc: defineAsyncComponent(() => import(`./windows/user/ViewDoc.vue`)),
    // ? Page
    MapSelectPoint: defineAsyncComponent(() => import(`./windows/map/SelectPoint.vue`)),
    SendReviewRequest: defineAsyncComponent(() => import(`./windows/review/SendReviewRequest.vue`)),
};

export { Components };
