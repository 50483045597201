<template>
  <button
      :stroke="stroke"
      :class="['ui-button', this.class]"
      :disabled="disabled"
  >

    <slot></slot>

    <div v-if="true">
      <Loading style="width: 20px;height: 20px;padding: 10px 0"
               v-show="btnLoading"
      />
    </div>


  </button>
</template>

<script>
export default {
  name: "ButtonExit",
  props: {
    btnLoading: {type: Boolean, default: false},
    stroke: {type: Boolean, required: false, default: false},
    disabled: {type: Boolean, required: false, default: false},
    class: {type: String, required: false, default: ""},
    paddingsForButton: {type: Boolean, default: false},
  },

};
</script>

<style lang="scss" scoped>
</style>
