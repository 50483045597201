export default {
    state: {
        list: []
    },
    mutations: {
        'window:add': (state, window) => {
            let i = state.list.findIndex(item => item.name === window.name && JSON.stringify(item.data) === JSON.stringify(window.data));
            i > -1 ? state.list.push(state.list.splice(i, 1)[0]) : state.list = [ ...state.list, window ];
        },
        'window:add:button': (state, { windowId, window }) => {
            return state.list[windowId]['window'] = window;
        },
        'window:remove': (state, id) => {
            return state.list = state.list.filter((item, i) => i !== id);
        }
    },
    actions: {
        setWindow({ commit }, [name, data, props = {}]) {
            return commit('window:add', { name, data, props });
        },
        addWindowBtn({ commit }, [windowId, window]) {
            return commit('window:add:button', { windowId, window });
        },
        closeWindow({ commit }, id) {
            return commit('window:remove', id);
        }
    },
    getters: {
        getWindow(state) {
            return state.list;
        }
    },
}