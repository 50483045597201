import store from "@/store";

export const beforeEnter = function (to, from, next) {
    const userAuthorized = store.getters["user/getUser"]?.authorized;
    if (!userAuthorized) {
        if (from.name) next({ name: from.name });
        else next({ path: "/" });
    } else {
        next();
    }
};
