<template>
  <div class="dropdown-input">
    <PlaceholderTextInput
        class="inputSearch"
        :class="{ active: showList }"
        :style="$attrs.style"
        :placeholder="placeholder"
        :model-value="value"
        :bigPaddings="true"
        :error="error"
        :img="[require('@/assets/img/search2.svg')]"
        @update:modelValue="
                value = $event;
                this.activeElement = 0;
                $emit('update:modelValue', $event);
            "
        @focus="this.showList = true"
        @blur="
                this.showList = false;
                if (clearAfterChoice) value = '';
            "
        @click="this.showList = true"
        @keydown="this.showList = true"
        @keydown.escape="
                this.showList = false;
                if (clearAfterChoice) value = '';
            "
        @keydown.up.prevent="decrement"
        @keydown.down.prevent="increment"
        @keydown.enter="
                $emit('update:choice', list[activeElement]);
                this.showList = false;
                if (this.clearAfterChoice) {
                    value = '';
                }
            "
    />
    <transition-group
        enter-active-class="fade-show"
        leave-active-class="fade-hide"
    >
      <ol
          class="list"
          v-show="showList && list.length > 0"
          ref="list"
          key="1"
      >
        <li
            class="item"
            v-for="(row, indx) of list"
            :class="{ active: indx == activeElement }"
            :key="row.id"
            @click="handleChoice(row)">
          <div class="text">
            {{ row.text }}
          </div>
          <span>{{ row.secondText }}</span>
        </li>
      </ol>
      <div
          v-show="!list.length && showList"
          key="2"
          class="list"
          :class="{ mobileVersion: !desktopVersion }"
      ></div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: "DropdownInput",
  inheritAttrs: false,
  emits: ["update:modelValue", "update:choice"],
  props: {
    placeholder: {type: String, required: false, default: ""},
    modelValue: {type: String, required: false},
    list: {type: Array, required: true}, //{id, text, secondText}
    clearAfterChoice: {type: Boolean, required: false, default: false},
    error: {type: Boolean, required: false, default: false},
    desktopVersion: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      value: "",
      showList: false,
      activeElement: 0,
    };
  },
  watch: {
    modelValue(newValue) {
      this.value = newValue;
    },
    showList() {
      this.$nextTick().then(this.focusElement);
    },
    activeElement() {
      this.focusElement();
    },
  },
  methods: {
    increment() {
      this.activeElement++;
      if (this.activeElement >= this.list.length) {
        this.activeElement = 0;
      }
    },
    decrement() {
      this.activeElement--;

      if (this.activeElement < 0) {
        this.activeElement = this.list.length - 1;
      }
    },
    handleChoice(row){
        this.showList = false;
        if (!this.desktopVersion && this.clearAfterChoice) {
          this.value = row.place_name;
        } else if (this.clearAfterChoice) {
          this.value = '';
        }
        this.$emit('update:choice', row);
      },
    focusElement() {
      if (this.$refs.list) {
        const nodes = this.$refs.list.children;
        const elementToFocus = nodes[this.activeElement];
        if (elementToFocus) {
          elementToFocus.parentElement.scrollTo({
            top: elementToFocus.offsetTop - 8,
            behavior: "smooth", // Плавна прокрутка
          });
        }
      }
    },
  },
  mounted() {
    this.value = this.modelValue;
  },
};
</script>

<style lang="scss">
.dropdown-input {
  position: relative;

  .inputSearch {
    --bg: --background-secondary;
    //height: 36px;
    border-bottom: none;

    &.active {
      z-index: 3;

      & input {
        background-color: white;
        border-color: #c9c8d7;
        border-bottom: none;
        border-radius: 4px 4px 0 0;
      }

      &::before {
        content: " ";
        display: block;
        height: 1px;
        width: 90%;
        position: absolute;
        top: 98%;
        left: 5%;
        background: #e6e6e6;
      }

      &::after {
        content: " ";
        display: block;
        height: 1px;
        border-bottom: 1px solid white;
        z-index: 4;
        position: absolute;
        bottom: -1px;
        left: 1px;
        width: calc(100% - 1px);
      }
    }
  }

  .list {
    position: absolute;
    z-index: 3;
    background-color: white;
    padding: 8px;
    border: 1px solid #c9c8d7;
    border-radius: 0px 0px 5px 5px;
    width: 100%;
    color: var(--text-primary);
    //transition: opacity 0.5s;
    max-height: 200px;
    overflow-x: hidden;
    border-top: none;

    .item {
      margin: 4px 0;
      padding: 4px;
      list-style-type: none;

      &.active {
        background-color: #d6d6d6;
        //border-radius: 4px;
        transition: 0.2s;
      }

      .text {
        font-weight: bold;
      }

      span {
        font-size: 12px;
      }

      &:hover {
        background-color: #eeeeee;
        //border-radius: 4px;
        cursor: pointer;
        transition: 0.2s;
      }
    }
  }

  .mobileVersion {
    position: relative;
  }
}


</style>
