<template>
    <div class="multi-range">
        <div class="multi-range-slider">
            <input type="range" ref="inputLeft" :min="min" :step="step" :max="max"
                @input="setLeftValue($event.target)"
            >
            <input type="range" ref="inputRight" :min="min" :step="step" :max="max"
                @input="setRightValue($event.target)"
            >

            <div class="slider">
                <div class="track"></div>
                <div class="range" :style="{
                    left: `${left.p}%`,
                    right: `${100 - right.p}%`
                }"></div>
                <div :class="['thumb left']" :style="{ left: `${left.p}%` }"></div>
                <div :class="['thumb right']" :style="{ right: `${100 - right.p}%` }"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "MultiRange",
    props: {
        min: { type: Number, default: 0 },
        max: { type: Number, default: 100 },
        step: { type: Number, default: 1 },
        value: { type: Array, default: () => ([0, 100]) }
    },
    data: () => ({
        left: {
            value: 0,
            p: 0
        },
        right: {
            value: 0,
            p: 0
        }
    }),
    watch: {
        value: {
            handler(newValue) {
                this.$refs.inputLeft.value = newValue[0];
                this.$refs.inputRight.value = newValue[1];
                this.setLeftValue(this.$refs?.inputLeft, newValue[0]);
                this.setRightValue(this.$refs?.inputRight, newValue[1]);
            },
            deep: true
        },
        'left.p'() {
            this.emit();
        },
        'right.p'() {
            this.emit();
        }
    },
    methods: {
        setLeftValue(input, value) {
            var min = parseInt(input.min),
                max = parseInt(input.max);

            this.left.value = Math.min(parseInt(value || input.value), parseInt(this.$refs?.inputRight?.value) - 1);

            this.left.p = ((this.left.value - min) / (max - min)) * 100;
        },
        setRightValue(input, value) {
            var min = parseInt(input.min),
                max = parseInt(input.max);

            this.right.value = Math.max(parseInt(value || input.value), parseInt(this.$refs?.inputLeft?.value) + 1);

            this.right.p = ((this.right.value - min) / (max - min)) * 100;
        },
        emit() {
            this.$emit('update', [this.left.value, this.right.value]);
        }
    },
    mounted() {
        this.$refs.inputLeft.value = this.value[0] || this.min;
        this.$refs.inputRight.value = this.max;

        this.setLeftValue(this.$refs?.inputLeft);
        this.setRightValue(this.$refs?.inputRight);
    }
}

</script>

<style lang="scss" scoped>

.multi-range {
	position: relative;
	max-width: 100%;
    width: 100%;
    height: 24px;
    box-sizing: border-box;

    .slider {
        position: relative;
        z-index: 1;
        height: 4px;
    
        & > .track {
            position: absolute;
            z-index: 1;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: 5px;
            background-color: var(--background-darked);
        }

        & > .range {
            position: absolute;
            z-index: 2;
            left: 25%;
            right: 25%;
            top: 0;
            bottom: 0;
            border-radius: 5px;
            background-color: var(--text-primary);
        }

        & > .thumb {
            position: absolute;
            z-index: 3;
            width: 16px;
            height: 16px;
            background-color: var(--text-primary);
            border-radius: 50%;
            box-shadow: 0 0 0 0 rgba(98,0,238,.1);
            transition: box-shadow .3s ease-in-out;

            &.left {
                left: 25%;
                transform: translate(-6px, -6px);
            }

            &.right {
                right: 25%;
                transform: translate(6px, -6px);
            }
        }
    }


    input[type=range] {
        position: absolute;
        pointer-events: none;
        -webkit-appearance: none;
        z-index: 2;
        height: 10px;
        width: 100%;
        opacity: 0;

        &::-webkit-slider-thumb {
            pointer-events: all;
            width: 30px;
            height: 30px;
            border-radius: 0;
            border: 0 none;
            background-color: red;
            -webkit-appearance: none;
        }

        &::-moz-range-thumb {
            pointer-events: all;
            z-index: 10;
        }
    }
}

</style>