import { createApp } from "vue";
import router from "./router";
import store from "./store";
import App from "./App";
import components from "./components/UI";
import { mapActions, mapGetters, mapState } from "vuex";
import VueMobileDetection from "vue-mobile-detection";

// * CSS
import "./assets/css/root.css";

// * Auth
import vue3GoogleLogin from "vue3-google-login";

// * Main Components
import { Components } from "./components";

const app = createApp(App);
app.use(VueMobileDetection);

app.mixin({
    components: { ...components, ...Components },
    computed: {
        ...mapState("user", ["user"]),
        ...mapGetters([
            "listRoles",
            "listCountries",
            "listBuildingTypes",
            "listWorkTypes",
            "listScale",
            "listTags",
            "listCurrency",
            "listPeriod",
            "getWindow",
            "listParticipantStatus",
            "listTopic",
        ]),
    },
    data: () => ({
        scrollY: 0,
        lockScroll: false,
        winWidth: 0,
        winHeight: 0,
    }),
    watch: {
        "user.authorized"(newValue, oldValue) {
            if (!newValue && oldValue) return this.router("/");
            if (!newValue && !oldValue) return this.router("/login");
        },
    },
    methods: {
        ...mapActions(["setToolpic", "loadListRoles", "setWindow", "closeWindow"]),
        router(url) {
            router.push(url);
        },
        redirect(link, target = true) {
            return window.open(link, target ? "_blank" : "_self");
        },
        AuthorizedRedirect(user = this.user, route = "/user") {
            if (user.authorized) {
                this.router(route);
            }
        },
        UnauthorizedRedirect(user = this.user, route = "/login") {
            if (!user.authorized) {
                this.router(route);
            }
        },
        NextRegisterRedirect(user = this.user) {
            if (user.id) {
                this.router(`/users/${user.id}`);
            }
            // else if ((user.authorized && !user.title) || !user.user_type) {
            //     this.router("/register/next");
            // }
        },
        async fetch(
            uri = "",
            data = {},
            method = "GET",
            json = true,
            headers = { "Content-Type": "application/json" }
        ) {
            let res = await fetch(`https://api.proconnect.construction${uri}`, {
                    body: method === "GET" ? undefined : json ? JSON.stringify(data) : data,
                    headers: { ...headers },
                    method,
                }),
                result = await res.json(),
                status = await res.status;
            return { status, result };
        },
        uts(UT, one, two, five) {
            if (`${UT}`.split("").reverse()[1] === "1") return `${UT}${five}`;
            if (`${UT}`.split("").reverse()[0] === "1") return `${UT}${one}`;
            if (+`${UT}`.split("").reverse()[0] >= 2 && +`${UT}`.split("").reverse()[0] <= 4) return `${UT}${two}`;
            return `${UT}${five}`;
        },
        timeago(time = Date.now()) {
            let msPerMinute = 60 * 1000,
                msPerHour = msPerMinute * 60,
                msPerDay = msPerHour * 24,
                elapsed = Date.now() - time;

            if (elapsed < msPerMinute)
                return `${this.uts(Math.round(elapsed / 1000), " секунда", " секунди", " секунд")} тому`;
            else if (elapsed < msPerHour)
                return `${this.uts(Math.round(elapsed / msPerMinute), " хвилина", " хвилини", " хвилин")} тому`;
            else if (elapsed < msPerDay)
                return `${this.uts(Math.round(elapsed / msPerHour), " година", " години", " годин")} тому`;
            else {
                let { day, month_name, year } = this.unix(time);
                return `${day} ${month_name} ${year}`;
            }
        },
        hasExtensionSimilarImage(url) {
            return url ? /\.(jpg|jpeg|png|gif|bmp|tiff|svg|webp|heif|heic)/i.test(url) : false;
        },
        showFile(file) {
            const fname = file.name ? this.getFile(file) : file;

            if (!this.hasExtensionSimilarImage(fname)) {
                this.redirect(fname);
            } else {
                this.setWindow([
                    "ViewDoc",
                    {
                        src: fname,
                        title: this.description,
                        list: [fname],
                    },
                ]);
            }
        },
        unix(unix = Date.now()) {
            const Months_name = [
                "січень",
                "лютий",
                "березень",
                "квітень",
                "травень",
                "червень",
                "липень",
                "серпень",
                "вересень",
                "жовтень",
                "листопад",
                "грудень",
            ];

            let date = new Date(unix),
                year = date.getFullYear(),
                day = date.getDate(),
                month = date.getMonth(),
                hours = date.getHours(),
                minutes = date.getMinutes(),
                seconds = date.getSeconds();

            if (month < 10) month = `0${month}`;
            if (day < 10) day = `0${day}`;
            if (hours < 10) hours = `0${hours}`;
            if (hours >= 24) hours = `0${hours - new Number(24)}`;
            if (minutes < 10) minutes = `0${minutes}`;
            if (minutes >= 60) minutes = `0${minutes - new Number(60)}`;
            if (seconds < 10) seconds = `0${seconds}`;
            if (seconds >= 60) seconds = `0${seconds - new Number(60)}`;

            return {
                year,
                day,
                month,
                month_name: Months_name[Number(month)],
                hours,
                minutes,
                seconds,
            };
        },
        getFile(file) {
            return URL.createObjectURL(file);
        },
        getFileName(file) {
            if (file instanceof File) return file.name;
            if (typeof file === "string") {
                let { pathname } = new URL(file);
                let t = pathname.split("/").find((item) => item.split(".").length !== 1);
                t = decodeURIComponent(t);
                return t;
            }
        },
        clipboard(text = "Proconnect") {
            return navigator.clipboard.writeText(text);
        },
        _setTimeout(callback, timeout = 0) {
            if (!callback) return;
            return setTimeout(callback, timeout || 0);
        },
    },
    async mounted() {
        this.winWidth = window.innerWidth;
        this.winHeight = window.innerHeight;
        document.querySelector("#app")?.addEventListener("scroll", () => {
            this.scrollY = document.querySelector("#app").scrollTop;
        });
    },
    async beforeCreate() {
        window.addEventListener("resize", () => {
            this.winWidth = window.innerWidth;
            this.winHeight = window.innerHeight;
        });
    },
});

app.use(router);
app.use(store);

app.use(vue3GoogleLogin, {
    clientId: `785199475899-8klfvri10v077780c5s41el6mv057r81.apps.googleusercontent.com`,
});

app.mount("#app");
