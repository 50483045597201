<template>
    <div class="ui-skeleton"></div>
</template>

<script>

export default {
    name: "UISkeleton",
    props: {}
}

</script>

<style lang="scss" scoped>

.ui-skeleton {
    display: block;
    width: 100%;
    height: 16px;
    position: relative;
    border-radius: 5px;
    mask-image: -webkit-radial-gradient(white, black);
    background: var(--background-secondary);
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        animation: SkeletonAnimation 1.6s linear 0.5s infinite normal none running;
        background: rgba(0, 0, 0, 0) linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.05), transparent) repeat scroll 0% 0%;
        transform: translateX(-100%);
        inset: 0px;
    }
}

@keyframes SkeletonAnimation {
    0% {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    50% {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        transform: translateX(100%);
    }
}

</style>