export default {
    state: {
        projects: {
            list: {
                updated_date: "Датою оновлення",
                //'rating':'Рейтингу',
            },
            desc: true,
            value: "updated_date",
            visible: false,
        },
        users: {
            list: {
                personal_rating: "Рейтингом",
                projects_count: "Кількістю проєктів",
                experience_years: "Досвідом, років",
                reviews_count: "Кількістю відгуків",
                team_size: "Кількістю команди",
                created_datetime: "Датою реєстрації на PRO Connect",
            },
            desc: true,
            value: "personal_rating",
            visible: false,
        },
    },
    mutations: {
        VISIBLE_INVERT: (state, { orderName }) => {
            state[orderName].visible = !state[orderName].visible;
        },
        DESC_SET: (state, { orderName, value }) => {
            state[orderName].desc = value;
        },
        DESC_INVERT: (state, { orderName }) => {
            state[orderName].desc = !state[orderName].desc;
        },
        VALUE_SET: (state, { orderName, value }) => {
            state[orderName].value = value;
        },
    },
    actions: {
        visibleInvert: (state, data) => {
            state.commit("VISIBLE_INVERT", data);
        },
        descSet: (state, data) => {
            state.commit("DESC_SET", data);
        },
        descInvert: (state, data) => {
            state.commit("DESC_INVERT", data);
        },
        valueSet: (state, data) => {
            state.commit("VALUE_SET", data);
        },
    },
    getters: {
        getOrder: (state) => (orderName) => {
            return state[orderName];
        },
    },
};
